import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";

const PageLogin = (props) => {
  const localUser = useUser();

  useEffect(() => {
    if (Cookies.get("token") !== undefined) {
      navigate("/home");
    }
    // if (Cookies.get("token") !== undefined || Cookies.get("token") !== null || Cookies.get("token") !== "") {
    //   navigate("/home");
    // }
  }, []);

  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setMsg("");
    const payload = {
      email: data.email,
      password: data.password,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/admin/login", form_data)
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          Cookies.set("token", response.data?.token);
          localUser.fetchData();
          navigate("/home");
        } else {
          setMsg("Error: " + response.data?.message);
        }
      })
      .catch(function (error) {});
  };

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url('${props?.setting?.background}')`,
        }}
      >
        <div className="flex flex-col justify-center items-center pb-20 w-full">
          <div className="mb-10 flex flex-col justify-center items-center space-y-2.5 w-full">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-1/2"
            >
              <div className="text-center font-bold mb-5   ">
                LOGIN STAFF
              </div>

              <div className="flex flex-col">
                <label className="text-black w-full">Email:</label>
                <input
                  autoFocus
                  className="border-2 px-2 py-2 rounded-sm"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="text-red-500 text-sm">
                    This field is required
                  </span>
                )}
              </div>

              <div className="flex flex-col ">
                <label className="text-black">Password:</label>
                <input
                  className="border-2 px-2 py-2 rounded-sm"
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <span className="text-red-500 text-sm">
                    This field is required
                  </span>
                )}
              </div>

              <div className="text-center font-bold text-red-500">{msg}</div>

              <button
                type="submit"
                className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
              >
                LOG IN
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLogin;
