import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../hook/useQuery";
import Cookies from "js-cookie";
import helper from "../../utils/helper";

const PageHome = (props) => {
  const query = useQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      window.location.href = "./login";
    }
  }, []);

  const logout = () => {
    Cookies.remove("token");
    window.location.href = "./login";
  };

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
        style={{
          backgroundPosition: 'center',
          backgroundImage: `url('${helper.getBackground(
            props?.setting?.background
          )}')`,
        }}
      >
        <div className="flex flex-col justify-center items-center pb-20">
          <div className="text-white font-bold mb-10 text-xl uppercase">
            {query.get("event")}
          </div>
          <div className="mb-10 flex flex-col justify-center items-center space-y-2.5 w-full">
            <a
              href={`./gate`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              GATES
            </a>
            <div className="w-full">
              <div className="border border-white w-full my-5"></div>
            </div>

            {/* <a
              href={`./distribution`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              DISTRIBUTION ONLINE - WITH RFID
            </a>

            <a
              href={`./distribution?rfid=no`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500 w-full text-center`}
            >
              DISTRIBUTION AT EVENT - NO RFID
            </a> */}

            <br />

            {/* <a
              href={`./checkin`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              CHECKIN
            </a>

            <a
              href={`./exit`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              CHECKOUT
            </a>

            <a
              href={`./survey`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              SURVEY
            </a> */}

            <button
              onClick={() => logout()}
              className={`mt-10 min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500 w-full text-center`}
            >
              LOGOUT
            </button>
            {/* <a
              href={`/redeem?event=${query.get("event")}`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-[#051f5b]  w-full text-center`}
            >
              REDEEM
            </a> */}

            {/* <a
              href={`/checkout?event=${query.get("event")}&id_gate=${query.get("id_gate")}`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-[#051f5b]  w-full text-center`}
            >
              CHECK OUT
            </a> */}

            {/* <a
              href="./event"
              className="min-w-[160px] mt-4 px-10 py-2 bg-red-500 rounded-lg text-white font-bold w-full text-center"
            >
              BACK
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHome;
