import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../hook/useQuery";
import Cookies from "js-cookie";
import helper from "../../utils/helper";
import axios from "axios";
import CONFIG from "../../constanta/config";

const PageEvent = (props) => {
  const query = useQuery();
  const [event, setEvent] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      window.location.href = "./login";
    }

    getGate();
  }, []);

  const logout = () => {
    Cookies.remove("token");
    window.location.href = "./login";
  };

  const getGate = () => {
    axios
      .get(CONFIG.URL + "/event/gate", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setEvent(response.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
        style={{
          backgroundPosition: 'center',
          backgroundImage: `url('${helper.getBackground(
            props?.setting?.background
          )}')`,
        }}
      >
        <div className="flex flex-col justify-center items-center pb-20">
          <div className="mb-10 flex flex-col justify-center items-center space-y-2.5 w-full">
            {event.map((v, index) => {
              return (
                <>
                  <div className="uppercase text-white font-bold text-2xl mb-2">
                    {v.gate}
                  </div>
                  <a
                    key={index}
                    href={`./checkin`}
                    className={`uppercase min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
                  >
                    Checkin & Re-entry
                  </a>

                  {/* <a
                    href={`./checkout?id_gate=${v.id_gate}&gate=${v.gate}`}
                    className={`uppercase min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500 w-full text-center`}
                  >
                    Checkout
                  </a> */}

                 
                </>
              );
            })}

            <a
              href={`./exit`}
              className={`uppercase min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500 w-full text-center`}
            >
              Checkout
            </a>

            <div className="w-full">
                    <div className="border border-white my-5"></div>
                  </div>
          </div>

          <button
            onClick={() => logout()}
            className={`mt-10 min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500 w-full text-center`}
          >
            LOGOUT
          </button>
        </div>
      </div>
    </>
  );
};

export default PageEvent;
