import { useEffect, useState } from "react";
import CONFIG from "../../constanta/config";
import axios from "axios";

import FormAddUser from "./add-user.from";
import Cookies from "js-cookie";
import helper from "../../utils/helper";

const PageAddUser = (props) => {
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);
  const [state, setState] = useState("add-user");

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      window.location.href = "./home";
    }
  }, []);

  const handleSuccesverification = (data) => {
    setUser(data);

    if (data.is_rsvp === "1") {
      // setState("exist");
      setState("rsvp-edit");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    } else {
      setState("rsvp");
    }
  };

  return (
    <>
      {
        <div
          className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
          style={{
            backgroundPosition: 'center',
          backgroundImage: `url('${helper.getBackground(
              props?.setting?.background
            )}')`,
          }}
        >
          <div className="flex flex-col justify-center items-center py-20 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              {/* VERIFICATION */}
              {state === "add-user" && (
                <FormAddUser
                  onSuccess={(data) => handleSuccesverification(data)}
                  options={options}
                />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageAddUser;
