import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import CONFIG from "../constanta/config";
import Cookies from "js-cookie";

const useUser = () => {
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(CONFIG.URL + "/user?limit=1000000", {
        headers: {
          token: Cookies.get("token"),
        },
      });

      if (response?.data?.status === "SUCCESS") {
        let localUser = JSON.stringify(response.data?.data);
        window.localStorage.setItem("LocalUser", localUser);
      }
    } catch (error) {}
  }, []);

  return { fetchData };
};

export default useUser;
